<template>
	<div class="wrapper-accordion">
		<TnAccordion
			:multiple="!content.single"
			:first-item-open="content.firstItemOpen"
			:dark="dark"
		>
			<TnAccordionItem
				v-for="(item, index) in items"
				v-bind="item"
				:key="`accordion-item-${index}`"
				:class="{ 'accordion-white-bg': $attrs.hasBackground && !dark }"
				:size="vuexStore.getters.isMobile ? 's' : 'm'"
				:dark="dark"
			>
				<!-- Icon -->
				<template
					v-if="!vuexStore.getters.isMobile"
					#icon
				>
					<img
						v-if="item['iconOrImage'] && item['iconOrImage'].hasOwnProperty('icon')"
						v-bind="item['iconOrImage'].icon"
					/>
					<TnIcon
						v-else-if="item['iconOrImage'] && item['iconOrImage'].hasOwnProperty('iconUI')"
						:name="item['iconOrImage']['iconUI']"
						size="l"
					/>
				</template>

				<!-- Content -->

				<TnNotificationInline
					v-if="item.infoBox"
					:dark="dark"
					class="margin-bottom-m infobox"
				>
					<RichText :text="item.infoBox" />
				</TnNotificationInline>

				<RichText
					:text="item.content"
					:dark="dark"
				/>
				<Component
					v-if="item.linkedComponent"
					class="margin-top-m"
					:component="item.linkedComponent"
					:is="getComponentName(item.linkedComponent)"
				></Component>
				<TnButton
					v-if="item.button"
					v-bind="item.button"
					@click="emit('click', item)"
					class="margin-top-m"
					:dark="dark"
				></TnButton>
			</TnAccordionItem>
		</TnAccordion>
	</div>
</template>
<script setup lang="ts">
import ButtonViewModel from "~/helpers/ViewModels/ButtonViewModel.js";
import getComponentName from "~/helpers/gizmo/getComponentName.js";
import type { Schema } from "./accordion.d.ts";
import { useStore } from "vuex";

interface Props extends Schema {
	dark: boolean;
}

const props = defineProps<Props>();

const vuexStore = useStore();

const emit = defineEmits(["click"]);

const items = computed(() => {
	let items = [];
	if (props.content.accordionItems) {
		items = JSON.parse(JSON.stringify(props.content.accordionItems));
	}

	return items.map((item) => {
		if (item.icon) {
			item["iconOrImage"] = {};
			item["iconOrImage"].icon = item.icon;
			delete item.icon;
		}
		if (item.ctaButton) item.button = ButtonViewModel(item.ctaButton);
		return item;
	});
});

const faqDataJsonld = (): string | null => {
	if (props.content?.enableFAQmarkup) {
		const structuredData = {
			"@context": "https://schema.org",
			"@type": "FAQPage",
			mainEntity: items.value?.map((tab) => ({
				"@type": "Question",
				name: tab?.title,
				acceptedAnswer: {
					"@type": "Answer",
					text: tab?.content.replace(/<[^>]+>|&nbsp;/gi, ""),
				},
			})),
		};

		return JSON.stringify(structuredData);
	}
	return null;
};

const jsonLdScript = faqDataJsonld();
if (jsonLdScript) {
	// scripts added through useHead are automatically removed when the component is unmounted
	useHead({
		script: [
			{
				type: "application/ld+json",
				children: jsonLdScript,
			},
		],
	});
}
</script>

<style lang="scss" scoped>
.wrapper-accordion {
	margin: 0 auto;

	.accordion-white-bg {
		background-color: $color-background-white !important;
	}
}

.infobox {
	font-size: inherit;
}

.button {
	margin-top: $spacing-xl;
}

.accordion-item-heading > .left-col-icon > img {
	max-height: 48px;
	max-width: 48px;
	object-fit: contain;
	display: block;
	margin: 0 auto;
}
</style>
